<template>
  <div>
    <MyHeader />
    <main id="content">
      <div class="green-belt"
           style="background-image: url('/img/img01.jpg');">
        <div class="container">
          <div class="row">
            <div class="col text-center">
              <h1 class="title mb-0 text-white">
                Owners
              </h1>
              <p class="text-white">
                ¿Necesita Incrementar sus rentas?
              </p>
              <a href="/contact"
                 class="btn btn-light">Join Be like owners comunnity</a>
            </div>
          </div>
        </div>
      </div>
      <br>
      <div class="container my-5">
        <div class="row align-items-center justify-content-between">
          <div class="col-12 col-md-3 pr-0">
            <img src="/img/logo-icon.jpg"
                 class="img-fluid d-block mx-auto">
          </div>
          <div class="col-12 col-md">
            <p>HBL es la mejor manera de incrementar sus rentas de la mano con el mejor servicio al cliente que cualquier  cliente  pueda recibir. Permítanos enseñarle como puede ser esto posible. Lo invitamos a disfrutar de un paseo por un mundo de experiencias, servicio y lujo que cambiara la manera vivir el leisure que usted y sus invitados se merecen.</p>
          </div>
        </div>
      </div>
      <div class="container">
        <hr class="mb-5">
        <div class="row align-items-center justify-content-center">
          <div class="col-12">
            <h4>¿QUE ES LO QUE NOS HACE UNICOS?</h4>
            <br>
            <ul>
              <li>Poder delegar el alquiler de su propiedad en profesionales  que estarán pendientes del cuidado de su huésped y de su propiedad.</li>
              <li>El  Poder darle la tranquilidad de que no tendrá que lidiar con las problemáticas  y quejas del huésped  tiene en otras vacation rentals; todo eso recaerá sobre nosotros.</li>
              <li>La Seguridad de conocer y atender al  huésped Ante, durante y  después de su llegada.</li>
              <li>Web Site con expouser y presencia internacional.</li>
              <li>Manejo unificado de todos los vacations rentals bajo un calendario único.</li>
              <li>Reportes seguros  y de manera periódica de sus ingresos.</li>
              <li>Inspección detallada y minuciosa de las propiedades.</li>
              <li>No habrá sorpresas ¡!!Todo está previsto  y programado desde antes de la llegada ya que proporcionamos al huésped unas vacaciones hechas a su medida de la mano con un servicio de concierge impecable  durante toda su estadía.</li>
              <li>Proporcionamos unas vacaciones al huésped hechas a su medida ofreciéndole  un servicio impecable  de Conciegre durante toda su estadía.</li>
              <li>Estricto manejo de las políticas de uso  de nuestras propiedades y capacidad de ocupación.</li>
              <li>Mejor control de la propiedad al momento efectuar el check in y del  check out. Mediante un historial previo que hemos adquirido durante el proceso de reservación de la propiedad y hasta el momento de la partida del cliente; teniendo control de cada detalle de la estadía del cliente  que se refleja en el Itinerario personalizado que cada huésped recibe por parte del Destination Specialist y que es entregado a cada cliente 48 horas antes de la llegada a la propiedad seleccionada.</li>
              <li>Seguridad de su propiedad  mientras se ocupa, gracias a las visitas diarias que hace nuestro Destination Concierge con el fin de dar seguimiento personalizado al itinerario del cliente  y para estar al tanto de cualquier detalle o cambio que el cliente quiera hacer durante su estadía o cualquier reporte que haga el personal  que se encuentre en la villa; nos ayudara a dar un seguimiento constante para la correcta operación  de ambas partes  (cliente-propiedad).</li>
              <li>Amenidades especiales cuando visite su propiedad. Gracias a la relación que se crea entre los propietarios y HomeBeLike, logramos identificar y conocer sus gustos y demás detalles que nos permitirán sorprenderlos cuando hagan uso de su propiedad. </li>
              <li>Servicio de Concierge  especial  y tarifas preferenciales para propietarios que  estén en nuestro pool de renta o en nuestro programa de exclusividad (Fidelidad HomeBeLike) y que quieran alojarse en otras propiedades de esta gran  familia. </li>
              <li>Bonos y descuentos especiales en  sus  estadías en otras propiedades por pertenecer a nuestra red HomeBeLike.</li>
              <li>Sistema de verificación de identidad del huesped desde nuestra plataforma. </li>
            </ul>
          </div>
        </div>
        <br>
      </div>
    </main>
    <MyFooter />
  </div>
</template>

<script>
export default {
  name: 'Owners',
};
</script>

<style scoped>

</style>
